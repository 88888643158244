.about-us-heading {
  margin-top: 5%;
  font-size: 3.0rem;
  position: relative; /* Position relative to enable absolute positioning for the underline */
}

.section-heading {
  margin-top: 5%;
}
.about-us-heading:after{
  content: "";
  position: absolute;
  bottom: -5px; /* Adjust the position to center the underline under the text */
  left: 50%; /* Start from the center */
  transform: translateX(-50%); /* Move it back to the left by half of its width */
  width: 30%; /* Adjust the width of the underline */
  height: 2px; /* Adjust the height of the underline */
  background-color: rgba(0, 0, 0, 0.1); /* Color of the underline */
}

.about-us-description {
  margin-top: 2%;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
}

.about-us-list, .contact-info, .social-media-list {
  list-style-type: none;
  padding-left: 0;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

.about-us-list li, .contact-info li, .social-media-list li {
  margin-bottom: 10px;
}

.about-us-list li::before, .contact-info li::before, .social-media-list li::before {
  content: "•";
  color: #000;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.about-us-list strong, .contact-info strong, .social-media-list strong {
  color: #333;
}


.text-center {
  text-align: center;
}

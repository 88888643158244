.engagement-heading {
    margin-top: 5%;
    font-size: 3.0rem;
    margin-bottom: 10%;
    position: relative; /* Position relative to enable absolute positioning for the underline */
  }
  
.engagement-heading:after {
content: ""; /* Create a pseudo-element for the underline */
position: absolute;
bottom: -5px; /* Adjust the position to center the underline under the text */
left: 50%; /* Start from the center */
transform: translateX(-50%); /* Move it back to the left by half of its width */
width: 75%; /* Adjust the width of the underline */
height: 2px; /* Adjust the height of the underline */
background-color: rgba(0, 0, 0, 0.1); /* Color of the underline */
}
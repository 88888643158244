/* Footer.css */

.footer {
    margin-top: 3%;
    background-color: #f8f9fa; /* Set the background color of the footer */
    padding: 20px 0; /* Add some padding to the top and bottom */
    text-align: center; /* Align the text to the center */
    min-height: 5vh;
}
.icon {
    font-size: 8px;
    color: #6c757d;
}
.footer p {
    margin: 0; /* Remove any default margins */
    color: #6c757d; /* Set the text color */
    font-size: 14px; /* Set the font size */
}

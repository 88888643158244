/* Service.css */
.service-text-content {
  margin-top: 2%;
}
.main {
  margin: 0;
  padding: 0;
  color: #000946;
  font-family: 'Montserrat', sans-serif;
}

.content {
  display: block;
  box-shadow: 1px 0 2px 1px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s; /* Add transition for background-color and transform */
}

.content:hover {
  background-color: #f0f0f0; /* Change background color on hover */
  transform: scale(1.05); /* Increase size slightly on hover */
}

.service-heading {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 3%;
}

.description {
  margin-top: 5%;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #555;
}

.image-container {
  max-height: 60vh;
  justify-content: end;
}

.image {
  width: auto;
  height: 45vh;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

/* Service.css */

.service-button {
  background-color: #333; /* Darker background color for better contrast */
  color: #fff;
  padding: 12px 24px; /* Increased padding for better button size */
  border: 2px solid #333; /* Add border for better visibility */
  border-radius: 25px; /* Increased border radius for smoother edges */
  cursor: pointer;
  margin-top: 20px; /* Increased margin for better spacing */
  font-family: 'Montserrat', sans-serif;
  font-size: 16px; /* Adjusted font size for better readability */
  text-transform: uppercase; /* Uppercase text for emphasis */
  transition: background-color 0.3s ease, color 0.3s ease; /* Added transition for smoother hover effect */
}

.service-button:hover {
  background-color: #222; /* Darker background color on hover */
  color: #ccc; /* Lighter text color on hover for contrast */
}

.service-button:focus {
  outline: none; /* Remove focus outline */
}



@media (max-width: 500px) {
  .service-text-content {
    margin-top: 2%;
    padding: 5%;
  }
  .content {
    display: flex;
    box-shadow: 1px 0 2px 1px rgba(0, 0, 0, 0.1);
    justify-content: center;
  }

  /* Adjusted styles for smaller screens */
  .service-heading {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    margin-bottom: 2%; /* Adjust margin for smaller screens */
  }

  .description {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    margin-bottom: 2%; /* Adjust margin for smaller screens */
  }
}

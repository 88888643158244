/* home.css */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Positioning adjustments */
.video-container {
  position: relative;
}
.main {
  margin: 0;
  padding: 0;
  color: #000946;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

h1.heading {
  font-weight: bold;
  margin-bottom: 3%;
  
}

p {
  font-size: 1.6rem;
  line-height: 1.6;
  color: #555;
  text-align: center;
}

.main-bg {
  
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}

.welcome{
  margin-left: 45%;
}

.text-content {
  margin-top: 3%;
  justify-content: right;
}

.video-container {
  max-height: 60vh;
  align-self: start;
}

video {
  margin-right: 45%;
  width: auto;
  height: 60vh;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}


.gallery-container {
  margin-top: 5%;
  justify-content: center;
  align-items: flex-start;
}

.load-more-button {
  background-color: #333; /* Darker background color for better contrast */
  color: #fff;
  padding: 12px 24px; /* Increased padding for better button size */
  border: 2px solid #333; /* Add border for better visibility */
  border-radius: 25px; /* Increased border radius for smoother edges */
  cursor: pointer;
  margin-top: 20px; /* Increased margin for better spacing */
  font-family: 'Montserrat', sans-serif;
  font-size: 16px; /* Adjusted font size for better readability */
  text-transform: uppercase; /* Uppercase text for emphasis */
  transition: background-color 0.3s ease, color 0.3s ease; /* Added transition for smoother hover effect */
}

.load-more-button:hover {
  background-color: #222; /* Darker background color on hover */
  color: #ccc; /* Lighter text color on hover for contrast */
}

h1.heading-2 {
  margin-top: 3%;
  
}
@media (max-width: 500px) {
  .video {
    justify-self: center;
    width: 100%;
  }
  
}


/* contact-us.css */





.form-group {
    margin-bottom: 15px;
}


.form-control {
    border-radius: 5px;
}

.btn-primary {
    display: block;
    margin: auto;
}

.form-label {
    font-size: 1.6rem;
    line-height: 1.6;
    color: #555;


}
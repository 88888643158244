
.services-container {
  display: flex;
  flex-wrap: wrap;
}

.services-heading {
  margin-top: 5%;
  font-size: 3.0rem;
  margin-bottom: 10%;
  position: relative; /* Position relative to enable absolute positioning for the underline */
}

.services-heading:after {
  content: ""; /* Create a pseudo-element for the underline */
  position: absolute;
  bottom: -5px; /* Adjust the position to center the underline under the text */
  left: 50%; /* Start from the center */
  transform: translateX(-50%); /* Move it back to the left by half of its width */
  width: 30%; /* Adjust the width of the underline */
  height: 2px; /* Adjust the height of the underline */
  background-color: rgba(0, 0, 0, 0.1); /* Color of the underline */
}


  
  .service {
    position: relative;
    flex: 1 0 100%; /* Each service item takes full width */
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .service-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .service:hover .service-image {
    transform: scale(1.1); /* Expand image on hover */
  }
  
  .service-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }

  .content-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
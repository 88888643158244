/* Navbar.css */

.navbar-styling {
  color: #fff9d5; /* Royal gold text color */
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  padding-right: 2%;
}
.logo {
  max-height: 100%; /* Set a maximum height relative to its parent (the navbar) */
  padding-left: 2%;
}
.social-icons{
  margin-left:20% ;
  mix-blend-mode: multiply;
  height: 25px;
  width: 25px;
}
.menu-icon {
  /* Add any menu icon styles here if needed */
}

.nav-links {
  list-style-type: none;
  display: flex;
  justify-content: flex-end; /* Align nav links to the right */
}

@media (min-width: 992px) {
  .navbar-collapse {
    display: flex !important; /* Override Bootstrap's default display setting */
    justify-content: flex-end; /* Align navbar links to the right */
    background-color: transparent; /* Override Bootstrap's default background color */
  }

  .navbar-toggler {
    display: none; /* Hide the toggle button on large screens */
  }
}
.nav-links li {
  margin-left: 15px; /* Adjust spacing between nav items */
}

.nav-links a {
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
}

.nav-link {
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  position: relative;
}

.nav-link:hover,
.nav-link.active {
  color: #ffd700;
}

/* Add a royal underline effect on hover and when active */
.nav-link::after {
  content: '';
  display: block;
  height: 2px;
  width: 0;
  background-color: #ffd700;
  margin-top: 2px;
  transition: width 0.3s ease-in-out;
  position: absolute;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.nav-link:not(:hover)::after {
  width: 0;
}

/* Add a royal underline effect */
.nav-links a::after {
  content: '';
  display: block;
  height: 2px;
  width: 0; /* Initially set to 0, so there's no underline */
  background-color: #ffd700;
  margin-top: 2px;
  transition: width 0.3s ease-in-out; /* Transition only the width property */
}

.nav-links a:hover::after {
  width: 100%; /* Expand the underline on hover */
}

